// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
Rails.start()
window.Rails = Rails
//require("@rails/ujs").start()
//require("turbolinks").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import LocalTime from "local-time"
LocalTime.start()

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

setTimeout( () => {
  $('.alert-fixed').remove()
},5000)

// FIXME support more than one reloading button
$('.button_to[data-reload-interval]').each( function(i,e) {
  setInterval(function() {
    $('.button_to[data-reload-interval] input[type=submit]').each( function(j,elem) {
      $(elem).trigger('click')
    })
  }, parseInt($(this).data('reload-interval'))*1000)
  return false
})

$('[data-delegate-click]').on('click', function(e) {
  e.preventDefault()
  let target = $(this).data('delegate-click')
  $(target).trigger('click')
})

$('a.tbody-expander').on('click', function(e) {
  e.preventDefault();
  let $this = $(e.target)
  $this.closest('div').find('tbody.compact').removeClass('compact')
  $this.remove()
})

$('[data-menu-filter]').on('keyup', function(e) {
  let m = e.target.closest('.dropdown-menu')
  let msg = m.querySelector('.dropdown-items-empty')
  msg.classList.add('d-none')
  let q = e.target.value.trim().toLowerCase()
  let c = 0
  m.querySelectorAll('.dropdown-item-filterable').forEach( col => {
    if( q.length == 0 || col.innerText.toLowerCase().includes(q) ) {
      col.classList.remove('d-none')
      c++
    } else {
      col.classList.add('d-none')
    }
  })
  if ( c == 0 ) {
    msg.classList.remove('d-none')
  }
})

$('[data-hover-append]').hover( function() {
  let appendText = $(this).data('hover-append')
  if(this.innerHTML.includes(appendText)) {
    this.innerHTML = this.innerHTML.replace(appendText, '')
  } else {
    this.innerHTML = this.innerHTML + appendText
  }
})

document.body.addEventListener( 'ajax:error', function(e) {
  alert("Sorry, something went wrong on the server. Please contact help@powerimporter.com.")
})

$('.ytmodal').on('show.bs.modal', function (e) {
    let ytm = $(e.target).find('iframe')
    ytm.attr('src', ytm.data('src'));
})

$('.ytmodal').on('hide.bs.modal', function (e) {
    $(e.target).find('iframe').removeAttr('src');
})

$('.modal-rich-field').on('hide.bs.modal', function(e) {
  let $target = $(e.target)
  let col = $target.find('select[name*="[column]"]').first().val()
  if(col) {
    $target.closest('.field').addClass('mapped')
  } else {
    $target.closest('.field').removeClass('mapped')
  }
})

let snapshotModal = $('#snapshot-view.modal').first()

if(snapshotModal) {
  let loadModal = () => {
    if(window.location.hash && window.location.hash.length>1) {
      Rails.ajax({
        type: 'get',
        dataType: 'json',
        url: snapshotModal.data('url') + `?id=${window.location.hash.substring(1)}`,
      })
    }
  }
  window.addEventListener('hashchange', loadModal)
  snapshotModal.on('hide.bs.modal', e => {
    window.location.hash = ''
  })
  loadModal()
}

window.$ = $

// document.addEventListener('turbolinks:load', () => {
//   // Call your functions here, e.g:
//   // initSelect2();
// });
